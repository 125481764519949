import {
  FETCH_QUOTATIONS
} from '../../constants'


export const fetchQuotations = () => {
  return {
    type: FETCH_QUOTATIONS
  }
}
