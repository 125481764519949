export default theme => ({
  equipment: {
    marginBottom: '20px'
  },
  door: {
    marginLeft: '31px',
    marginTop: '5px'

  },
  icon: {
    color: theme.palette.text.primary
  }
})
