export default {
  grid: {
    margin: '20px'
  },
  sidebar: {
    paddingLeft: '40px'
  },
  validation: {
    marginTop: '40px'
  }
}
